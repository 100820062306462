import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import { LoadingClass } from 'utils/common';
import { Helmet } from 'react-helmet';

const Loading = new LoadingClass();
export default class About extends React.Component {
	componentDidMount() {
		Loading.dataLoaded = true;
		Loading.Load();
		if (typeof document !== `undefined`) document.body.id = 'about';
	}
	componentWillUnmount() {
		Loading.Exit();
	}
	render() {
		Loading.MinTimeLoad();
		const content = this.props.data.content.frontmatter;
		const setting = this.props.data.setting.frontmatter;
		const seo = content.seo;

		return (
			<Layout titleText={content.title} mainId="about">
				<section className="full">
					<Helmet>
						{seo.seo_shortdesc && <meta name="description" content={seo.seo_shortdesc} />}
						{seo.seo_image && <meta name="image" content={seo.seo_image} />}
						{seo.seo_keywords && <meta name="keywords" content={seo.seo_keywords} />}
						{seo.seo_shortdesc && <meta property="og:description" content={seo.seo_shortdesc} />}
						{seo.seo_image && <meta property="og:image" content={seo.seo_image} />}
						{seo.seo_shortdesc && <meta name="twitter:description" content={seo.seo_shortdesc} />}
						{seo.seo_image && <meta name="twitter:image" content={seo.seo_image} />}
					</Helmet>
					<h1>{content.heading}</h1>
					<div>
						<p>{content.paragraph1}</p>
						<p>{content.paragraph2}</p>
					</div>
					<div>
						<span>CONTACT US</span>

						<div>
							<div>
								<span>
									Email
									<a
										href={`mailto:${setting.contact.email}`}
										target="_blank"
										rel="noopener noreferrer"
									>
										{setting.contact.email}
									</a>
								</span>
								<span>
									follow us on instagram
									<a href={`https://instagram.com/${setting.contact.instagram}`}>
										@{setting.contact.instagram}
									</a>
								</span>
							</div>

							<div>
								<span>
									<a
										className="acw_3 link"
										href={setting.contact.address_link}
										target="_blank"
										rel="noopener noreferrer"
									>
										{setting.contact.address}
									</a>
								</span>
							</div>
						</div>
					</div>
				</section>
			</Layout>
		);
	}
}

export const query = graphql`
	query($slug: String!) {
		content: markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			frontmatter {
				title
				heading
				paragraph1
				paragraph2
				seo {
					seo_shortdesc
					seo_keywords
					seo_image {
						childImageSharp {
							fluid(maxWidth: 1200) {
								...GatsbyImageSharpFluid_noBase64
							}
						}
					}
				}
			}
		}
		setting: markdownRemark(frontmatter: { issetting: { eq: true }, contenttype: { eq: "general_setting" } }) {
			frontmatter {
				title
				web_name
				header_title
				contact {
					email
					instagram
					address
					address_link
				}
			}
		}
	}
`;
